* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-3,
.col-md-6,
.col-xs-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-3 {
  float: left;
  width: 20%;
}

.col-xs-9 {
  width: 75%;
  float: left;
}

.clearfix:after {
  clear: both;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.panel {
  margin-bottom: 10px;
  /* background-color: #fff; */
  border: 1px solid transparent;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel-heading {
  height: 100px;
  background-color: turquoise;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-green {
  border: 2px dashed #398439;
}

.panel-green .panel-heading {
  background-color: #398439;
}

.green {
  color: #398439;
}

.blue {
  color: #337ab7;
}

.red {
  color: #ce7f7f;
}

.panel-primary {
  border: 2px dashed #337ab7;
}

.panel-primary .panel-heading {
  background-color: #337ab7;
}

.yellow {
  color: #ffcc00;
}

.panel-yellow {
  border: 2px dashed #ffcc00;
}

.panel-yellow .panel-heading {
  background-color: #ffcc00;
}

.panel-red {
  border: 2px dashed #ce7f7f;
}

.panel-red .panel-heading {
  background-color: #ce7f7f;
}

.huge {
  font-size: 30px;
}

/* .panel-heading {
  color: #fff;
} */

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-right {
  text-align: right;
}

.under-number {
  font-size: 20px;
}

@media (min-width: 992px) {
  .col-md-6 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .col-lg-3 {
    float: left;
    width: 20%;
  }
}

.form-control.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #eee;
  padding: 0 8px;
}

.filtering-table thead th:first-child {
  width: 70px;
}
.table tfoot tr {
  border-bottom-width: 1px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-color: #eeeeee;
}

.filter-pagination {
  display: inline-block;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button {
  font-size: 16px;
  border: 0 !important;
  width: auto;
  padding: 14px 20px;
  margin: 0 5px;
  border-radius: 10px;
  display: inline-block;
  background-color: #fff;
  color: #216fed;
}
.filter-pagination button:hover {
  background: #216fed;
  color: #fff;
}
.filter-pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}
.filter-pagination button[disabled]:hover {
  background: #216fed;
  opacity: 0.5;
  color: #fff;
}
.table-index input[type="number"] {
  background: #f0f0f0 !important;
  color: #969ba0;
  width: 70px;
  border: 0;
  background-color: #f8f8f8;
  padding: 6px 0 6px 10px;
}
@media only screen and (max-width: 575px) {
  .filter-pagination .previous-button,
  .filter-pagination .next-button {
    padding: 8px 10px;
    font-size: 14px;
  }
}
